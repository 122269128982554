import { Autocomplete, Box, Button, FormControl, TextField, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import ReactMarkdown from 'react-markdown';
import BookGif from '../animations/book.gif';
import api from '../api/axios';
import { COLORS } from "../core/const/colors";
import { ErrorMessage } from '../core/widgets/ErrorMessage';
import './question.css';

export enum QuestionView {
    question,
    loading,
    answer,
}

interface Option {
  value: string;
  label: string;
}

type QuestionData = {
    game: Option | null
    question: string
}

const fullFlexCentered: React.CSSProperties = { height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center", padding: 4 }
const whiteBoxFlex: React.CSSProperties = { backgroundColor:"white", maxWidth: "60rem", width: "100%", maxHeight: "40rem", height: "90vh", padding: 4, borderRadius: "25px", display: "flex", flexDirection: "column", justifyContent: "space-around" }

function Question() {
    const [view, setView] = useState<QuestionView>(QuestionView.question);
    const {control, handleSubmit, formState: { errors } } = useForm<QuestionData>();
    const [options, setOptions] = useState<Option[]>([]);
    const [question, setQuestion] = useState<string>("");
    const [answer, setAnswer] = useState<string>("");

    useEffect(() => {
        const fetchFile = async () => {
            const response = await fetch("handled_games.txt"); // Chemin relatif au fichier dans public
            const text = await response.text();
            setOptions(parseTextFile(text));
        };

        fetchFile();
    }, []);

    const parseTextFile = (text: string): Option[] => {
        return text
        .split('\n')
        .filter((line) => line.includes('<?:!>')) // Filtrer uniquement les lignes qui respectent ce format
        .map((line) => {
            const [value, label] = line.split('<?:!>');
            return { value: value.trim(), label: label.trim() };
        });
    };

    const onSubmit:SubmitHandler<QuestionData> = async (data: QuestionData) => {
        setView(QuestionView.loading)
        api.post('iaQuery/question', {
            "queryText": data.question,
            "game": data.game?.value,
        }).then((res) => {
            setAnswer(res.data.answer)
            setQuestion(data.question)
            setView(QuestionView.answer)
        }).catch((error) => {
            control.setError(error.field ?? "root", { message: error.message || 'Erreur inconnue' });
        })
    };
    
    return (
        <>
            <Box sx={{background: COLORS.gradiantPs, ...fullFlexCentered}}>
                <Box sx={{...whiteBoxFlex}} className="shadow-lg">
                    {_buildBody()}
                </Box>
            </Box>
        </>
    );

    function _buildBody() {
        switch (view) {
            case QuestionView.loading:
                return (_buildLoading())
            case QuestionView.answer:
                return (_buildAnswer())
            default:
                return (_buildQuestion());
        }
    }

    function _buildLoading() {
        return (
        <>
            <Box>
                <Box className="lottie-container">
                    <img src={BookGif} alt='Animation loading' />
                </Box>
                <Typography variant='body1' align='center'>Analyse des règles</Typography>
            </Box>
        </>
        )
    }

    function _buildAnswer() {
        return (
        <>
            <Box sx={{overflow:"hidden", display: "flex", flexDirection: "column"}}>
                <Typography variant="h1" sx={{textAlign: "center"}}>Voici la réponse !</Typography>
                <Box sx={{overflowY:"scroll", height:"100%"}}>
                    <Typography variant='body1' sx={{fontWeight:"700", textAlign:"center"}}>Question initiale : {question}</Typography>
                    <ReactMarkdown>{answer}</ReactMarkdown>
                </Box>
                <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={() => setView(QuestionView.question)}>Poser une autre question</Button>
            </Box>
        </>)
    }

    function _buildQuestion() {
        return (
        <>
        <Typography variant="h1" sx={{textAlign: "center"}}>Pose ta question</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl fullWidth margin="normal">
                    <Controller
                        name="game"
                        control={control}
                        defaultValue={null}
                        render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={options}
                                getOptionLabel={(option) => option.label}
                                onChange={(_, data) => field.onChange(data)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Choisis ton jeu" variant="outlined" />
                                )} />
                        )} />
                </FormControl>

                {/* TexteArea */}
                <FormControl fullWidth margin="normal">
                    <Controller
                        name="question"
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Question is required' }}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                id="question"
                                label="Pose ta question"
                                multiline
                                rows={4}
                                error={!!errors.question}
                                helperText={errors.question ? errors.question.message : ''} />
                        )} />
                </FormControl>

                {/* Bouton de soumission */}
                <ErrorMessage>
                    {errors.root?.message}
                </ErrorMessage>
                <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
                    Que dit la règle ?
                </Button>
            </form>
        </>);
    }
}

export default Question;
