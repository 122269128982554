import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { RegisterFormType } from '../HomeScreen';

type SuccessScreenProps = {
  setView: React.Dispatch<React.SetStateAction<RegisterFormType>>;
  view: RegisterFormType;
};

const SuccessScreen: React.FC<SuccessScreenProps> = ({ setView, view }) => {
  return (
    <>
      {view === RegisterFormType.createSuccess
        ? _buildCreateSuccess(setView)
        : _buildLoginSuccess()}
    </>
  );
};

export default SuccessScreen;

function _buildLoginSuccess() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Alert severity="success">
        <AlertTitle>Connexion réussie !</AlertTitle>
        Vous allez être redirigé
      </Alert>
    </Box>
  );
}

function _buildCreateSuccess(
  setView: React.Dispatch<React.SetStateAction<RegisterFormType>>,
) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      textAlign="center"
    >
      <Alert severity="success" sx={{ mb: 2 }}>
        <AlertTitle>Inscription réussie !</AlertTitle>
        Vous pouvez vous connecter dès maintenant
      </Alert>
      <Button onClick={() => setView(RegisterFormType.login)}>
        Je me connecte
      </Button>
    </Box>
  );
}
