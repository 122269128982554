import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import api from '../../api/axios';
import { SubmitButton } from '../../core/widgets/Buttons';
import { ErrorMessage } from '../../core/widgets/ErrorMessage';
import { BaseTextField } from '../../core/widgets/TextField';
import { RegisterFormType } from '../HomeScreen';

// Définir les types des données de formulaire
type SignInFormData = {
  email: string;
  password: string;
};

type OnSubmit = {
  email: string;
  password: string;
  formType: RegisterFormType;
};

type SignInFormProps = {
  view: RegisterFormType;
  handleSuccessCreate: Function;
  handleSuccessLogin: Function;
};
const SignInForm: React.FC<SignInFormProps> = ({
  view,
  handleSuccessCreate,
  handleSuccessLogin,
}) => {
  // Spécifier le type des données de formulaire ici
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<SignInFormData>();

  async function onSubmit({
    email,
    password,
    formType,
  }: OnSubmit): Promise<void> {
    try {
      if (formType === RegisterFormType.login) {
        // Utiliser async/await pour attendre la réponse de l'API
        const response = await api.post('users/connectUser', {
          email,
          password,
        });
        handleSuccessLogin(response); // Appel de la fonction en cas de succès
      } else if (formType === RegisterFormType.register) {
        // Utiliser async/await pour attendre la réponse de l'API
        const response = await api.post('users/createUser', {
          email,
          password,
        });
        handleSuccessCreate(response); // Appel de la fonction en cas de succès
      }
    } catch (error: any) {
      // Gestion des erreurs
      control.setError(error.field ?? 'root', {
        message: error.message || 'Erreur inconnue',
      });
    }
  }

  const handleFormSubmit: SubmitHandler<SignInFormData> = async (
    data: SignInFormData,
  ) => {
    await onSubmit({ ...data, formType: view });
  };

  return (
    <>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
          }}
          render={({ field, fieldState }) => (
            <BaseTextField
              {...field}
              label="Email"
              type="email"
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : null}
              fullWidth
              margin="normal"
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{
            required: 'Password is required',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long',
            },
          }}
          render={({ field, fieldState }) => (
            <BaseTextField
              {...field}
              label="Password"
              type="password"
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : null}
              fullWidth
              margin="normal"
            />
          )}
        />

        <ErrorMessage>{errors.root?.message}</ErrorMessage>
        <SubmitButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={isSubmitting}
        >
          {view === RegisterFormType.login ? 'Je me connecte' : "Je m'inscris"}
        </SubmitButton>
      </form>
    </>
  );
};

export default SignInForm;
