const primaryHard = '#65a1fa';
const secondaryHard = '#ffb054';
const primaryHarder = '#5a91f7';
const secondaryHarder = '#ffa342';
const primary = '#9bc8ff';
const secondary = '#ffd29b';
const primarySoft = '#c1ddff';
const secondarySoft = '#ffe3c1';
const primarySofter = '#e5f2ff';
const secondarySofter = '#fff4e6';
const textMain = '#484848';
const background = 'white';

export const COLORS = {
  primary: primary,
  secondary: secondary,
  primarySoft: primarySoft,
  secondarySoft: secondarySoft,
  primarySofter: primarySofter,
  primaryHard: primaryHard,
  secondaryHard: secondaryHard,
  primaryHarder: primaryHarder,
  secondaryHarder: secondaryHarder,
  secondarySofter: secondarySofter,
  gradiantPs: `linear-gradient(to bottom right, ${primarySoft}, ${secondarySoft})`,
  gradiantPsSoft: `linear-gradient(to bottom right, ${primarySofter}, ${secondarySofter})`,
  textMain: textMain,

  background: background,
};
