import { Box, Button, Grid2, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../core/const/colors';
import img from '../img/homebackground.jpg';
import SignInForm from './widgets/SignInForm';
import SuccessScreen from './widgets/SuccessForm';

const gridItemStyle: React.CSSProperties = {
  height: '100vh',
  scrollSnapAlign: 'start',
};

const coverFullHeighImgStyle: React.CSSProperties = {
  height: '100%',
  width: '100%',
  padding: 16,
  borderRadius: '25px',
  position: 'relative',
  objectFit: 'cover',
};

const boxOverlayStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: '15px',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  borderRadius: '10px',
  backgroundColor: '#d7d3c94a',
  padding: '20px 0px',
  minWidth: 'calc(100% - 30px)',
};

const textOverlayStyle: React.CSSProperties = {
  fontSize: '16px', // Taille de la police
  fontWeight: 'bold',
  textAlign: 'center',
  zIndex: 1, // Assurez-vous que le texte est au-dessus de l'image
  color: 'white',
};

export enum RegisterFormType {
  login,
  register,
  loginSuccess,
  createSuccess,
}

function Home() {
  const [view, setView] = useState<RegisterFormType>(RegisterFormType.login);
  const navigate = useNavigate();

  // Gestion du changement de l'option sélectionnée
  const handleViewChange = (newView: RegisterFormType) => {
    if (newView != null) {
      setView(newView);
    }
  };

  const handleSuccessCreate = () => {
    setView(RegisterFormType.createSuccess);
  };
  const handleSuccessLogin = () => {
    setView(RegisterFormType.loginSuccess);
    setTimeout(() => {
      navigate('/questions');
    }, 1500);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        scrollSnapType: 'y mandatory',
        overflowY: {
          xs: 'scroll',
          md: 'hidden',
        },
      }}
    >
      <Grid2 container sx={{ height: '100vh', bgcolor: COLORS.background }}>
        <Grid2 size={{ xs: 12, md: 6 }} sx={gridItemStyle}>
          <Box sx={{ position: 'relative', height: '100%' }}>
            <img src={img} alt="" style={coverFullHeighImgStyle} />
            <Box sx={boxOverlayStyle}>
              <Typography sx={textOverlayStyle}>
                Un doute sur les règles ? <br />
                <br />
                Posez vos questions, nous avons les réponses !
              </Typography>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 6 }} sx={gridItemStyle}>
          <Box
            sx={{
              px: 4,
              alignContent: 'center',
              height: '100%',
            }}
          >
            {view === RegisterFormType.login ||
            view === RegisterFormType.register
              ? buildForm(
                  view,
                  handleViewChange,
                  handleSuccessCreate,
                  handleSuccessLogin,
                )
              : buildSuccessForm(view, setView)}
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default Home;

function buildForm(
  view: RegisterFormType,
  handleViewChange: (newView: RegisterFormType) => void,
  handleSuccessCreate: () => void,
  handleSuccessLogin: () => void,
) {
  return (
    <>
      <Box sx={{ mb: 6 }}>
        <Typography variant="h2">
          {view === RegisterFormType.login
            ? 'De retour ? Connecte toi !'
            : 'Nouveau ici ? Crée un compte !'}
        </Typography>
      </Box>

      <SignInForm
        view={view}
        handleSuccessCreate={handleSuccessCreate}
        handleSuccessLogin={handleSuccessLogin}
      />

      <Box sx={{ mt: 1 }}>
        <Typography>
          {view === RegisterFormType.register
            ? "T'as déjà un compte ? "
            : 'Pas encore de compte ? '}
          <Button
            variant="text"
            onClick={() =>
              handleViewChange(
                view === RegisterFormType.register
                  ? RegisterFormType.login
                  : RegisterFormType.register,
              )
            }
            sx={{ padding: 0, minWidth: 'auto', textTransform: 'none' }}
          >
            {view === RegisterFormType.register
              ? 'Connecte toi !'
              : 'Crée en un !'}
          </Button>
        </Typography>
      </Box>
    </>
  );
}

function buildSuccessForm(
  view: RegisterFormType,
  setView: React.Dispatch<React.SetStateAction<RegisterFormType>>,
) {
  return (
    <>
      <SuccessScreen setView={setView} view={view} />
    </>
  );
}
