import axios, { AxiosInstance } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`, // Remplace avec ton API si besoin
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    // Récupérer le token depuis localStorage
    const token = localStorage.getItem('token');

    // Si le token existe, l'ajouter dans l'en-tête Authorization
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Gestion des erreurs de configuration
    return Promise.reject(error);
  },
);

// Intercepteur de réponses : Stocker le 'new-jwt' dans le localStorage si présent
api.interceptors.response.use(
  (response) => {
    // Vérifie si l'en-tête 'new-jwt' est présent
    const newJwt = response.headers['new-jwt'];
    if (newJwt) {
      // Stocke le nouveau JWT dans le localStorage
      localStorage.setItem('token', newJwt);
    }

    return response; // Retourne la réponse inchangée
  },
  (error) => {
    // Gère les erreurs 401 et autres ici si besoin
    if (error.response && error.response.status === 401) {
      window.location.href = '/'; // Rediriger vers la page d'accueil si 401
    }

    error.field = error.response.data.field;
    error.message = error.response.data.message;
    return Promise.reject(error); // Propager l'erreur
  },
);

export default api;
