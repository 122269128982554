import { createTheme, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { COLORS } from './core/const/colors';
import Home from './home/HomeScreen';
import Question from './questions/QuestionScreen';

// TypeScript type for custom theme properties
const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    secondary: {
      main: COLORS.secondary,
    },
    text: {
      primary: COLORS.textMain,
    },
  },
  typography: {
    fontWeightRegular: 500, // Poids normal (par défaut)
    fontWeightMedium: 600, // Poids moyen
    fontWeightBold: 700, // Poids gras
    h1: {
      color: COLORS.primary,
      fontWeight: 600,
      fontSize: '2rem',
      lineHeight: '2.5rem',
    },
    h2: {
      color: COLORS.textMain,
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      textAlign: 'center',
    },
    h6: {
      color: COLORS.textMain,
      fontSize: '1.2rem',
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: COLORS.textMain, // Couleur du texte non sélectionné
          backgroundColor: COLORS.primarySoft,
          '&.Mui-selected': {
            backgroundColor: COLORS.primaryHard, // Couleur du bouton sélectionné
            color: '#fff', // Couleur du texte lorsque sélectionné
            '&:hover': {
              backgroundColor: COLORS.primaryHard, // Couleur lors du survol
              color: '#fff',
            },
          },
          '&:hover': {
            backgroundColor: COLORS.primary, // Couleur lors du survol
            color: '#fff',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Appliquer le texte coloré par défaut sur les boutons avec fond principal
          '&.MuiButton-contained': {
            backgroundColor: COLORS.primaryHard,
            color: '#fff', // Couleur du texte lorsque le bouton est contenant
            '&:hover': {
              backgroundColor: COLORS.primaryHarder, // Couleur lors du survol
              color: '#fff',
            },
          },
        },
      },
    },
  },
});

function App(): JSX.Element {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/questions" element={<Question />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
